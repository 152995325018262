import React from "react";
import Select, { components } from "react-select";

import AddContact from "./modals/AddContact";
import EditContact from "./modals/EditContact";
import { UserAddIcon } from "@heroicons/react/outline"
import ContactsField from "./fields/ContactsField";
import TaxSelect from "./fields/TaxSelect";

class QBOSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      taxes: [],
      currencies: [],
      accounts: [],
      terms: [],
      hideTaxes: false,
      hideCurrency: false,
      loading: true,
      showAddContact: false,
      error: false,
    };
  }
  componentDidMount = async () => {
    const settings = this.props.connectionSettings;

    this.props.globalData("accounts", settings.accounts);
    this.props.globalData("taxes", settings.taxes);
    this.props.globalData("products", settings?.products);

    await this.setState({
      terms: settings.terms,
      accounts: settings.accounts,
      taxes: settings.taxes,
    });

    this.props.globalData("preferences", settings.connectionPreferences);

    if (
      settings.connectionPreferences.hasOwnProperty("defaultMessage") &&
      !this.props.settings.hasOwnProperty("custom_message")
    ) {
      this.props.updateSetting("custom_message", settings.connectionPreferences.defaultMessage);
    }
    if (settings.connectionPreferences.defaultTerms) {
      await this.updateTerms(settings.connectionPreferences.defaultTerms, false);
    }

    if (
      this.props.settings.currency === "" &&
      settings.currencies.includes(this.props.hubspotData.properties.deal_currency_code)
    ) {
      // If deal has a currency and it's in the list, select it
      this.props.updateSetting(
        "currency",
        this.props.hubspotData.properties.deal_currency_code,
        true
      );
      console.log("dealCurrency");
    } else if (this.props.settings.currency === "" && settings.connectionPreferences.defaultCurrency) {
      // If default currency set by QBO then use it
      this.props.updateSetting("currency", settings.connectionPreferences.defaultCurrency, true);
      console.log("connectionPreferences");
    } else if (this.props.settings.currency === "" && this.props.preferences?.defaultCurrency) {
      // If default currency set in settings then use it
      console.log("defaultCurrency");
      this.props.updateSetting("currency", this.props.preferences.defaultCurrency, true);
    } else if (this.props.settings.currency === "") {
      // Otherwise use the first currency on the list
      this.props.updateSetting("currency", this.props.settings.currency[0], true);
      console.log("onlyCurrency");
    }

    if (settings.connectionPreferences.defaultCurrency) {
      settings.currencies.push(settings.connectionPreferences.defaultCurrency);
    }
    
    if(settings.currencies.length > 1) {
      this.setState({
        currencies: settings.currencies,
      });
    }

    this.setState({
      loading: false,
    });
  };
  addNewContact(contact) {
    this.props.updateSetting("contact", contact.value, false);
    if (contact?.email) {
      this.props.updateSetting("contactEmail", contact.email, false);
    }
    this.setState({ showAddContact: false });
    this.setState({ updateContactsField: true });
    this.setState({ updateContactsField: false });
  }
  cancelNewContact() {
    this.setState({ showAddContact: false });
  }
  replaceContactData() {
    // replaces contact data when updated via the edit contact modal
    this.props.toggleEditContact();
    this.setState({ updateContactsField: true });
    this.setState({ updateContactsField: false });
  }
  updateContact(value, contactData = false) {
    if (contactData?.defaultTax) {
      this.props.updateSetting("contactHasCustomTax", contactData.defaultTax);
    }
    this.props.updateSetting("contact", value, false);
  }
  updateTax = (taxType) => {
    const { country } = this.props?.connectionData;

    const updateInvoices = country === "US";
    const updateLineItems = country !== "US";

    // This tax type is for US connections only and means QBO will
    // Auto-calculate based on location.
    if (taxType === 'TAX') {
      this.props.updateSetting("taxType", taxType, updateInvoices, updateLineItems);
    } else {
      const tax = this.props.getTaxFromTaxType(taxType);
      this.props.updateSetting("taxType", tax.type, updateInvoices, updateLineItems);
      this.props.updateSetting("tax", tax.rate, updateInvoices, updateLineItems);
    }
  }

  updateReference(value) {
    this.props.updateSetting("reference", value, true);
  }
  async updateTerms(value, hasChanged = true) {
    let terms = this.state.terms.find((x) => x.id === value);
    await this.props.updateSetting("terms", terms, hasChanged);
  }
  updateEmail(value) {
    this.props.updateSetting("contactEmail",value,true);
  }
  updateContact(value, contactData = false) {
    if (contactData?.defaultTax) {
      this.props.updateSetting("contactHasCustomTax", contactData.defaultTax);
    }
    this.props.updateSetting("contact", value, true);
  }
  render() {
    const disableTaxes = (this.props.preferences?.country === "US" && !this.props.hasEditableTax)
      || (this.props.preferences?.country !== "US" && this.props.allEditableTaxesAreCustom);
    
    const addContactLink = (
      <span className="text-xs">
        No contact found in QuickBooks
        <br />
        {this.props.preferences.hasOwnProperty("add_contacts") &&
          this.props.preferences.add_contacts === true && (
            <button
              className="underline text-platform"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showAddContact: true });
              }}
            >
              Add New
            </button>
          )}
      </span>
    );

    const CurrencyControl = ({ children, ...props }) => {
      let selectedOption = props.getValue();
      let classes =
        "currency-flag rounded-full h-4 w-4 bg-center ml-2 mr-1 currency-flag-" +
        selectedOption[0].value.toLowerCase();
      return (
        <components.Control {...props}>
          <div className={classes}></div>
          {children}
        </components.Control>
      );
    };
    const CurrencyOption = (props) => {
      let selectedOption = props.data.value ? props.data.value.toLowerCase() : null;
      console.log(props)
      let classes =
        "currency-flag rounded-full h-4 w-4 bg-center mr-2 currency-flag-" + selectedOption;
      return (
        <components.Option {...props}>
          <div className={(props.isSelected ? "text-white " : "") + "flex items-center"}>
            <div className={classes}></div>
            {props.children}
          </div>
        </components.Option>
      );
    };

   const currencyList = this.state.currencies.map((x) => {
      return { value: x, label: x };
    })

    let taxList = this.state.taxes.map((x) => {
      return { value: x.type, label: x.name };
    });

    if (this.props.preferences?.country === "US") {
      taxList = [...[{ value: "TAX", label: "Automated" }], ...taxList];
    } else {
      taxList = [...[{ value: false, label: "Not Set" }], ...taxList];
    }

    const taxValue = () => {
      const country = this.props.preferences?.country;

      if (country === "US" && !this.props.hasEditableTax) {
        return false;
      }

      const value = taxList.find((x) => x.value === this.props.settings.taxType);

      if (country === "US" && !value) {
        this.updateTax('TAX');
        return;
      }

      return value;
    }
    
    function selectTheme(theme) {
      return ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#CAE7C6",
          primary50: "#CAE7C6",
          primary: "#2ca01c",
        },
        spacing: {
          ...theme.spacing,
          baseUnit: 3,
        },
      });
    }
    
    return (
      <div className="flex gap-2 xl:gap-4">
        {this.props.settings.currency ? (
          <AddContact
            open={this.state.showAddContact}
            setClose={(e) => this.setState({ showAddContact: false })}
            hubspotData={this.props.hubspotData}
            addNewContact={this.addNewContact.bind(this)}
            cancelNewContact={this.cancelNewContact.bind(this)}
            setError={this.props.setError}
            setSuccess={this.props.setSuccess}
            platformStyles={this.props.platformStyles}
            connectionData={this.props.connectionData}
            b2c={this.props.preferences?.b2c}
            currencyList={currencyList}
            currentCurrency={this.props.settings.currency}
            taxes={taxList}
            selectTheme={selectTheme}
          />
        ) : null}
        {this.props.settings.currency ? (
          <EditContact
            open={this.props.showEditContact}
            setClose={this.props.toggleEditContact}
            contactId={this.props.settings.contact}
            replaceContactData={this.replaceContactData.bind(this)}
            setError={this.props.setError}
            setSuccess={this.props.setSuccess}
            connectionData={this.props.connectionData}
            platformStyles={this.props.platformStyles}
            currencyList={currencyList}
            currentCurrency={this.props.settings.currency}
            taxes={taxList}
            selectTheme={selectTheme}
          />
        ) : null}
        <div className="flex flex-col items-start flex-1 gap-1">
          <label>
            <span className="flex flex-row text-xs text-grey">
              <div className="flex-1">Contact</div>
            </span>
            <ContactsField
              updateContact={this.updateContact.bind(this)}
              selectedContact={this.props.settings.contact}
              contactName={
                this.props.preferences?.b2c || this.props.preferences?.searchByEmail
                  ? this.props.hubspotData.contactData?.email
                  : this.props.hubspotData?.companyData?.name
              }
              addContactLink={addContactLink}
              updateTerms={this.updateTerms.bind(this)}
              updateEmail={this.updateEmail.bind(this)}
              update={this.state?.updateContactsField}
              platformStyles={this.props.platformStyles}
              b2c={this.props.preferences?.b2c}
            />
          </label>
          {this.props.settings.contact && this.props.connectionData?.plan !== "starter" ? (
            <button
              className="flex justify-center text-sm underline text-platform"
              onClick={(e) => this.props.toggleEditContact()}
            >
              <UserAddIcon className="inline w-4 mr-1" />
              Edit Contact
            </button>
          ) : (
            <button
              className="flex justify-center text-sm underline text-platform"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showAddContact: true });
              }}
            >
              <UserAddIcon className="inline w-4 mr-1" />
              Add New Contact
            </button>
          )}
        </div>
        <div className="flex flex-wrap items-start w-full gap-2 xl:gap-4">
          <label className="flex-shrink">
            <span className="flex flex-row text-xs text-grey">Terms</span>
            <div className="flex">
              {!this.state.loading && (
                <Select
                  className="w-32 text-sm xl:w-48"
                  defaultValue={this.props.settings.terms}
                  options={this.state.terms.map((x) => {
                    return { value: x.id, label: x.label };
                  })}
                  placeholder="Terms"
                  theme={selectTheme}
                  onChange={(selectedOption) => {
                    this.updateTerms(selectedOption.value, true);
                  }}
                />
              )}
            </div>
          </label>
          {(this.state?.currencies && this.state.currencies.length) > 1 && (
            <label className="flex-shrink">
              <span className="flex flex-row text-xs text-grey">Currency</span>
              <div className="flex">
                <Select
                  className="w-32 text-sm xl:w-48"
                  classNamePrefix=""
                  defaultValue={currencyList.find((x) => x.value === this.props.settings.currency)}
                  components={{ Option: CurrencyOption, Control: CurrencyControl }}
                  options={currencyList}
                  placeholder="Currency"
                  theme={selectTheme}
                  onChange={(selectedOption) => {
                    this.props.updateSetting("currency", selectedOption.value, true);
                  }}
                />
              </div>
            </label>
          )}
          
          {this.state.hasOwnProperty("taxes") && this.state.taxes.length ? (
            <TaxSelect
              value={taxValue()}
              list={taxList}
              update={this.updateTax}
              disabled={disableTaxes}
              country={this.props.preferences?.country}
              platformStyles={this.props.platformStyles}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default QBOSettings;
